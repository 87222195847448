.pdf-container {
    /* position: relative;
    overflow-x: hidden; */
    background-color: #eee;
    display: flex;
    align-items: center;
    justify-content: center;
}
.pdf-thumbnails {
    position: fixed;
    left: 0;top: 0;
    z-index: 99;
    width: 30%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    transition: 0.5s;
    transform: translateX(-100%);
}
.pdf-thumbnails-active {
    transform: translateX(0%);
}

.pdf-content {
    transition: 0.5s;
    position: relative;
    z-index: 0;
    padding: 30px;
}
.pdf-content-active {
    transform: translateX(30%);
}
.toolbar {
    padding: 10px 0;
}