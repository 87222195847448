
html, body {
    margin: 0;
    padding: 0;
    /* width: 100vw; */
    overflow-x: hidden;
}

html, body.page {
    height: 100%;
}

.wrap {
    min-height: 100%;
    height: auto;
    overflow: auto;
    margin-top: 90px;
    padding: 0 17.5%;
    width: 65%;
    align-items: center;

    /* Equal to height of footer */
    /* But also accounting for potential margin-bottom of last child */
    margin-bottom: -360px;
}

/* Header */
header {
    padding: 0 17.5% 0 17.5%;
    z-index: 100;
    width: 65%;
    height: 90px;
    position: fixed;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;

}

#header_logo {
    width: 200px;
    height: auto;
}

#header_menu_list {
    display: flex;
    flex-direction: row;
    list-style-type: none;
    align-items: center;
}

.header_menu_list__item {
    margin-right: 32px;
}

.header_menu_list__item_link {
    text-decoration: none;
    color: #000000;
    font-size: 16px;
    font-family: "Harmonia", Arial, sans-serif;
    line-height: 1.55;
    font-weight: 400;
    background-position: center center;
    border-color: transparent;
    border-style: solid;
}

.header_menu_list__item:hover .header_menu_list__underline {
    animation: header_menu_list__underline-anim 0.5s linear forwards;
    background-color: #058dd9;
    background-position: center center;
    height: 2px;
}

@keyframes header_menu_list__underline-anim {
    0% {
        opacity: 0;
        transform: translateX(0px)translateY(0px)rotate(0deg)scale(1,1);
        animation-timing-function: linear;
    }
    1% {
        opacity: 0;
        transform: translateX(0px)translateY(10px)rotate(0deg)scale(1,1);
        animation-timing-function: ease-in-out;
    }
    100% {
        opacity: 1;
        transform: translateX(0px)translateY(0px)rotate(0deg)scale(1,1);
    }
}

.btn-link.logout {
    cursor: pointer;
    padding: 0;
}

.header_button {
    width: 170px;
    height: 40px;
    background-color: #058dd9;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    border-width: 1px;
    border-radius: 5px;
    margin-left: 5px;
}

.header_button:active, .header_button:hover {
    color: #fff;
    background-color: #286090;
    border-color: #204d74;
}

.header_button__link {
    text-decoration: none;
    font-weight: 700;
    color: #fff;
    font-size: 16px;
    font-family: "Harmonia", Arial, sans-serif;
    line-height: 1.55;
}

.header_button__link:active, .header_button__link:hover, .header_button__link:focus {
    color: #fff;
    text-decoration: none;
    outline: none!important;
    outline-offset: 0!important;
    border-color: transparent;
}

#description_btn__link_get, #description_btn__link_buy {
    display: flex;
    flex-direction: column;
    width: 250px;
    height: 55px;
    margin-bottom: 20px;
    text-decoration: none;
}

/* Description */
#description {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    /*background-image: url("../../images/description/bg.gif");*/
    background-position: left;
    background-size: cover;
}

.description_title {
    font-size: 40px;
    font-family: "Harmonia", Arial, sans-serif;
    line-height: 1.15;
    font-weight: 700;
    animation: description_titie-anim 1s linear forwards;
}

#description_title.is-paused {
    animation-play-state: paused;
}

@keyframes description_titie-anim {
    0% {
        opacity: 0;
        transform: translateX(0px)translateY(0px)rotate(0deg)scale(1,1);
        animation-timing-function: linear;
    }
    1% {
        opacity: 0;
        transform: translateX(-2000px)translateY(0px)rotate(0deg)scale(1,1);
        animation-timing-function: ease-out;
    }
    100% {
        opacity: 1;
        transform: translateX(0px)translateY(0px)rotate(0deg)scale(1,1);
    }
}

#description_text {
    color: #000000;
    font-size: 18px;
    font-family: "Harmonia", Arial, sans-serif;
    line-height: 1.55;
    font-weight: 400;
    animation: description_text-anim 1s linear forwards;
    margin-top: 18px;
    margin-bottom: 18px;
}

#description_text.is-paused {
    animation-play-state: paused;
}

@keyframes description_text-anim {
    0% {
        opacity: 0;
        transform: translateX(0px)translateY(0px)rotate(0deg)scale(1,1);
        animation-timing-function: linear;
    }
    1% {
        opacity: 0;
        transform: translateX(0px)translateY(10px)rotate(0deg)scale(1,1);
        animation-timing-function: ease-out;
    }
    100% {
        opacity: 1;
        transform: translateX(0px)translateY(0px)rotate(0deg)scale(1,1);
    }
}

#decription_info {
    display: flex;
    flex-direction: column;
}

.description_btn {
    width: 250px;
    height: 55px;
    border: 1px solid #058dd9;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

#d_btn_get {
    transition: all 0.8s ease-in-out !important;
    color: #000000;
    font-family: "Harmonia", Arial, sans-serif;
    line-height: 1.55;
    font-weight: 700;
}

#d_btn_buy {
    transition: all 0.8s ease-in-out !important;
}

#d_btn_get:hover {
    background-color: #058dd9;
    color: #fff;
    border-radius: 5px;
}

#d_btn_buy:hover {
    background-color: #000000;
    color: #fff;
    border-radius: 5px;
}

#description_btn__link_get {
    text-decoration: none;
    font-size: 20px;
    font-family: "Harmonia", Arial, sans-serif;
    line-height: 1.55;
    font-weight: 500;
    color: #000000;
}

#description_btn__link_buy {
    text-decoration: none;
    font-size: 20px;
    font-family: "Harmonia", Arial, sans-serif;
    line-height: 1.55;
    font-weight: 400;
    color: #000000;
    transition: all 0.8s ease-in-out !important;
}

#description_preview__blocks {
    position: absolute;
    left: 245px;
    top: 231px;
    width: 1470px;
}

#description_preview__law {
    width: 500px;
    height: auto;
    grid-area: law;
    z-index: 3
}

#description_preview__pen {
    width: 140px;
    animation: pen-animation 12s infinite linear;
    grid-area: pen;
    grid-row: 6;
    grid-column: 2;
    position: relative;
    top: -60px;
    left: -100px;
}

#description_preview__pen.is-paused {
    animation-play-state: paused;
}

.description_preview__pixel {
    background-color: #058dd9;
    background-position: center center;

    height: 2px;
    width: 2px;
}

#description_preview__pixel_1 {
    animation: description_preview__pixel_1-anim 1s infinite linear;
    grid-area: pixel1;
    position: relative;
    grid-column: 1;
    grid-row: 4;
    top: 15px;
    left: 55px;
}

@keyframes description_preview__pixel_1-anim {
    0% {
        opacity: 1;
        transform: translateX(0px)translateY(0px)rotate(0deg)scale(1,1);
        animation-timing-function: linear;
    }
    1% {
        opacity: 1;
        transform: translateX(0px)translateY(0px)rotate(0deg)scale(1,1);
        animation-timing-function: linear;
    }
    100% {
        opacity: 1;
        transform: translateX(103px)translateY(-72px)rotate(0deg)scale(1,1);
    }
}

#description_preview__pixel_2 {
    animation: description_preview__pixel_2-anim 1.1s infinite linear;
    grid-area: pixel1;
    position: relative;
    grid-column: 1;
    grid-row: 4;
    top: 15px;
    left: 55px;
}

@keyframes description_preview__pixel_2-anim {
    0% {
        opacity: 1;
        transform: translateX(0px)translateY(0px)rotate(0deg)scale(1,1);
        animation-timing-function: linear;
    }
    1% {
        opacity: 1;
        transform: translateX(0px)translateY(0px)rotate(0deg)scale(1,1);
        animation-timing-function: linear;
    }
    10% {
        opacity: 1;
        transform: translateX(0px)translateY(0px)rotate(0deg)scale(1,1);
        animation-timing-function: linear;
    }
    100% {
        opacity: 1;
        transform: translateX(103px)translateY(-72px)rotate(0deg)scale(1,1);
    }
}

#description_preview__pixel_3 {
    animation: description_preview__pixel_3-anim 1.1s infinite linear;
    grid-area: pixel3;
    position: relative;
    grid-row: 4;
    grid-column: 4;
    z-index: 0;
    position: relative;
    top: 20px;
}

@keyframes description_preview__pixel_3-anim {
    0% {
        opacity: 1;
        transform: translateX(0px)translateY(0px)rotate(0deg)scale(1,1);
        animation-timing-function: linear;
    }
    1% {
        opacity: 1;
        transform: translateX(0px)translateY(0px)rotate(0deg)scale(1,1);
        animation-timing-function: linear;
    }
    10% {
        opacity: 1;
        transform: translateX(0px)translateY(0px)rotate(0deg)scale(1,1);
        animation-timing-function: ease-out;
    }
    100% {
        opacity: 1;
        transform: translateX(-87px)translateY(-72px)rotate(0deg)scale(1,1);
    }
}

#description_preview__pixel_4 {
    animation: description_preview__pixel_4-anim 1s infinite linear;
    grid-area: pixel3;
    position: relative;
    grid-row: 4;
    grid-column: 4;
    z-index: 0;
    position: relative;
    top: 20px;
}

@keyframes description_preview__pixel_4-anim {
    0% {
        opacity: 1;
        transform: translateX(0px)translateY(0px)rotate(0deg)scale(1,1);
        animation-timing-function: linear;
    }
    1% {
        opacity: 1;
        transform: translateX(0px)translateY(0px)rotate(0deg)scale(1,1);
        animation-timing-function: ease-out;
    }
    100% {
        opacity: 1;
        transform: translateX(-87px)translateY(-72px)rotate(0deg)scale(1,1);
    }
}

#description_preview__loop {
    width: 130px;
    animation: loop-animation 8s infinite linear;
    grid-area: loop;
    grid-row: 3;
    grid-column: 2;
}

#description_preview__loop.is-paused {
    animation-play-state: paused;
}

#description_preview__shield {
    width: 150px;
    animation: shield-animation 8s infinite linear;
    grid-area: shield;
    grid-row: 2;
    grid-column: 6;
}

#description_preview__shield.is-paused {
    animation-play-state: paused;
}

#description_preview__pc {
    width: 200px;
    animation: pc-animation 9s infinite linear;
    grid-area: pc;
    grid-row: 5;
    grid-column: 6;
    position: relative;
    top: -40px;
}

#description_preview__pc.is-paused {
    animation-play-state: paused;
}

#boiler {
    background-color: #fff;
    grid-area: b;
}
#description_preview {
    /*background-image: url("../../images/description/blocks.png");*/
    background-size: contain;
    background-repeat: no-repeat;
    background-position-y: center;
    display: grid;
    grid-template-columns: repeat(7, 100px);
    grid-template-rows: repeat(7, 100px);
    grid-template-areas: ". . . loop . . ."
    ". law law law law law ."
    ". law law law law law ."
    "pen law law law law law shield"
    ". law law law law law ."
    ". law law law law law ."
    ". . . . pc . .";
}

@keyframes pen-animation {
    0% {
        opacity: 1;
        transform: translateX(0px)translateY(0px)rotate(0deg)scale(1,1);
        animation-timing-function: ease-out;
    }
    1% {
        opacity: 1;
        transform: translateX(0px)translateY(0px)rotate(0deg)scale(1,1);
        animation-timing-function: ease-out;
    }
    51% {
        opacity: 1;
        transform: translateX(81px)translateY(43px)rotate(0deg)scale(1,1);
        animation-timing-function: ease-out;
    }
    100% {
        opacity: 1;
        transform: translateX(0px)translateY(0px)rotate(0deg)scale(1,1);
    }
}

@keyframes loop-animation {
    0% {
        opacity: 1;
        transform: translateX(0px)translateY(0px)rotate(0deg)scale(1,1);
        animation-timing-function: linear;
    }
    51% {
        opacity: 1;
        transform: translateX(-22px)translateY(13px)rotate(0deg)scale(1,1);
        animation-timing-function: ease-out;
    }
    100% {
        opacity: 1;
        transform: translateX(0px)translateY(0px)rotate(0deg)scale(1,1);
    }
}

@keyframes shield-animation {
    0% {
        opacity: 1;
        transform: translateX(0px)translateY(0px)rotate(0deg)scale(1,1);
        animation-timing-function: linear;
    }
    51% {
        opacity: 1;
        transform: translateX(9px)translateY(-9px)rotate(0deg)scale(1,1);
        animation-timing-function: ease-out;
    }
    100% {
        opacity: 1;
        transform: translateX(0px)translateY(0px)rotate(0deg)scale(1,1);
    }
}

@keyframes pc-animation {
    0% {
        opacity: 1;
        transform: translateX(0px)translateY(0px)rotate(0deg)scale(1,1);
        animation-timing-function: linear;
    }
    11% {
        opacity: 1;
        transform: translateX(0px)translateY(0px)rotate(0deg)scale(1,1);
        animation-timing-function: linear;
    }
    12% {
        opacity: 1;
        transform: translateX(0px)translateY(0px)rotate(0deg)scale(1,1);
        animation-timing-function: ease-out;
    }
    56% {
        opacity: 1;
        transform: translateX(-22px)translateY(13px)rotate(0deg)scale(1,1);
        animation-timing-function: ease-out;
    }
    100% {
        opacity: 1;
        transform: translateX(0px)translateY(0px)rotate(0deg)scale(1,1);
    }
}

/* Benefits */

#benefits {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 40px;
}

.section_title {
    color: #000000;
    font-size: 50px;
    font-family: "Harmonia", Arial, sans-serif;
    line-height: 1.55;
    font-weight: 500;
    background-position: center center;
    border-color: transparent;
    border-style: solid;
}
#benefits_items {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.benefits_item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* height: 640px; */
}

.right {
    background-position: right;
}

.benefits_item__preview_number {
    position: relative;
    z-index: 1;
    width: 200px;
    height: auto;
}

.benefits_item__preview_number-left {
    bottom: 300px;
    left: 140px;
}
.benefits_item__preview_number-right {
    bottom: 200px;
    right: 200px;
}

.rotate180 {
    transform: rotate(180deg);
}

.benefits_item__preview_image {
    position: relative;
    width: 520px;
    height: auto;
}

.benefits_item__preview_image-left {
    /* top: 50px; */
    animation: preview_image-left-anim 2s linear forwards;
}

.benefits_item__preview_image-left.is-paused {
    animation-play-state: paused;
}


@keyframes preview_image-left-anim {
    0% {
        opacity: 0;
        transform: translateX(0px)translateY(0px)rotate(0deg)scale(1,1);
        animation-timing-function: linear;
    }
    1% {
        opacity: 0;
        transform: translateX(0px)translateY(0px)rotate(0deg)scale(1,1);
        animation-timing-function: ease-in-out;
    }
    100% {
        opacity: 1;
        transform: translateX(-83px)translateY(-47px)rotate(0deg)scale(1.1,1.1);
    }
}

.benefits_item__preview_image-right {
    top: 100px;
    animation: preview_image-right-anim 2s linear forwards;
}

.benefits_item__preview_image-right.is-paused {
    animation-play-state: paused;
}

@keyframes preview_image-right-anim {
    0% {
        opacity: 0;
        transform: translateX(0px)translateY(0px)rotate(0deg)scale(1,1);
        animation-timing-function: linear;
    }
    1% {
        opacity: 0;
        transform: translateX(0px)translateY(0px)rotate(0deg)scale(1,1);
        animation-timing-function: ease-in-out;
    }
    100% {
        opacity: 1;
        transform: translateX(116px)translateY(-69px)rotate(0deg)scale(1.1,1.1);
    }
}
.benefits_item__description {
    display: flex;
    flex-direction: column;
    height: 300px;
    justify-content: center;
}

.benefits_item__description_title {
    color: #000000;
    font-size: 35px;
    font-family: "Harmonia", Arial, sans-serif;
    line-height: 1.55;
    font-weight: 700;
    background-position: center center;
    border-color: transparent;
    border-style: solid;
    animation: benefits_item__description_title-anim 1s linear forwards;
    margin: 0;
}

.benefits_item__description_title.is-paused {
    animation-play-state: paused;
}

@keyframes benefits_item__description_title-anim {
    0% {
        opacity: 0;
        transform: translateX(0px)translateY(0px)rotate(0deg)scale(1,1);
        animation-timing-function: linear;
    }
    1% {
        opacity: 0;
        transform: translateX(0px)translateY(10px)rotate(0deg)scale(1,1);
        animation-timing-function: ease-out;
    }
    100% {
        opacity: 1;
        transform: translateX(0px)translateY(0px)rotate(0deg)scale(1,1);
    }
}


.benefits_item__description_text {
    color: #8f8f8f;
    font-size: 20px;
    font-family: "Harmonia", Arial, sans-serif;
    line-height: 1.55;
    font-weight: 400;
    background-position: center center;
    border-color: transparent;
    border-style: solid;
    animation: benefits_item__description_text-anim 1.4s linear forwards;
    margin: 0;
}

.benefits_item__description_text.is-paused {
    animation-play-state: paused;
}

@keyframes benefits_item__description_text-anim {
    0% {
        opacity: 0;
        transform: translateX(0px)translateY(0px)rotate(0deg)scale(1,1);
        animation-timing-function: linear;
    }
    1% {
        opacity: 0;
        transform: translateX(0px)translateY(20px)rotate(0deg)scale(1,1);
        animation-timing-function: linear;
    }
    30% {
        opacity: 0;
        transform: translateX(0px)translateY(20px)rotate(0deg)scale(1,1);
        animation-timing-function: ease-out;
    }
    100% {
        opacity: 1;
        transform: translateX(0px)translateY(0px)rotate(0deg)scale(1,1);
    }
}

.benefits_item__preview {
    /*background-image: url('../../images/benefits/cloud.png');*/
    background-repeat: no-repeat;
    height: 640px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.benefits_item__preview2 {
    /*background-image: url('../../images/benefits/cloud2.png');*/
}

.pr20 {
    padding-right: 40px;
}

.pl20 {
    padding-left: 40px;
}

/* Usage */

#usage_container {
    height: 820px;
    background-color: #f6f6fe;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.cloud_top {
    height: 150px;
    /* width: 2780px; */
    display: flex;
    /*background-image: url('../../images/usage/cloud_top.png');*/
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;
}

.cloud_bottom {
    height: 150px;
    display: flex;
    /* width: 2780px; */
    /*background-image: url('../../images/usage/cloud_bottom.png');*/
    background-size: cover;
    background-position: top;
}

#usage_items {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.usage_item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 5px;
    background-color: #ffffff;
    background-position: center center;
    border-color: transparent;
    border-style: solid;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
    height: 478px;
    width: 250px;
    text-align: center;
}

.usage_item__preview {
    width: 70px;
    height: auto;
    padding-top: 30px;
}

.mr30 {
    margin-right: 30px;
}

.usage_item__title {
    color: #000000;
    font-size: 25px;
    font-family: "Harmonia", Arial, sans-serif;
    line-height: 1.55;
    font-weight: 600;
    background-position: center center;
    border-color: transparent;
    border-style: solid;
}

.usage_item__text {
    color: #000000;
    font-size: 16px;
    font-family: "Harmonia", Arial, sans-serif;
    line-height: 1.55;
    font-weight: 400;
    background-position: center center;
    border-color: transparent;
    border-style: solid;
}

.usage_button {
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 365px;
    height: 55px;
    border-width: 1px;
    border-radius: 5px;
    background-position: center center;
    border-color: #058dd9;
    border-style: solid;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}
.usage_button__text {
    color: #000000;
    font-size: 20px;
    font-family: "Harmonia", Arial, sans-serif;
    line-height: 1.55;
    font-weight: 500;
    margin-right: 10px;
}

.usage_button__image {
    width: 45px;
    height: auto;
    animation: usage_button__image-anim 6s infinite linear;
}

.usage_button__image.is-paused {
    animation-play-state: paused;
}

#demo_item__images {
    display: flex;
    flex-direction: column;
}

@keyframes usage_button__image-anim {
    0% {
        opacity: 1;
        transform: translateX(0px)translateY(0px)rotate(0deg)scale(1,1);
        animation-timing-function: ease-in-out;
    }
    50% {
        opacity: 1;
        transform: translateX(15px)translateY(0px)rotate(0deg)scale(1,1);
        animation-timing-function: ease-in-out;
    }
    100% {
        opacity: 1;
        transform: translateX(0px)translateY(0px)rotate(0deg)scale(1,1);
    }
}



.usage_button__link {
    text-decoration: none;
}

/* Demo */
#demo {
    height: 680px;
}
#demo_items {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.demo_item {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    text-align: center;
}

.demo_item__title {
    color: #000000;
    font-size: 16px;
    font-family: "Harmonia", Arial, sans-serif;
    line-height: 1.55;
    font-weight: 700;
    background-position: center center;
    border-color: transparent;
    border-style: solid;
}
.demo_item__image {
    width: 430px;
    height: auto;

}
#demo_item__images {
    display: grid;
    grid-template-columns: repeat(5, 86px);
    grid-template-rows: repeat(5, 87,8px);
    /* grid-template-areas: ". . . loop . . ."
                         ". law law law law law ."
                         ". law law law law law ."
                         "pen law law law law law shield"
                         ". law law law law law ."
                         ". law law law law law ."
                         ". . . . pc . ."; */
}

#demo_item__sign {
    position: relative;
    width: 182px;
    height: auto;
    top: 250px;
    left: 150px;
    animation: demo_item__sign-anim 3s linear forwards;
}

#demo_item__sign.is-paused {
    animation-play-state: paused;
}

@keyframes demo_item__sign-anim {
    0% {
        opacity: 1;
        transform: translateX(0px)translateY(0px)rotate(0deg)scale(1,1);
        animation-timing-function: linear;
    }
    1% {
        opacity: 1;
        transform: translateX(0px)translateY(0px)rotate(0deg)scale(0,0);
        animation-timing-function: ease-in-out;
    }

    100% {
        opacity: 1;
        transform: translateX(0px)translateY(0px)rotate(0deg)scale(1,1);
    }
}

#demo_item__arrow_left {
    position: relative;
    background-position: center center;
    border-color: transparent;
    border-style: solid;
    animation: demo_item__arrow_left-anim 4s linear forwards;
    width: 70px;
    height: auto;
    top: 290px;
    left: 220px;
    transform: rotate(315deg);
    -webkit-transform: rotate(315deg);
}

#demo_item__arrow_left.is-paused {
    animation-play-state: paused;
}

@keyframes demo_item__arrow_left-anim {
    0% {
        opacity: 1;
        transform: translateX(0px)translateY(0px)rotate(0deg)scale(1,1);
        animation-timing-function: linear;
    }
    1% {
        opacity: 1;
        transform: translateX(0px)translateY(0px)rotate(80deg)scale(0,0);
        animation-timing-function: linear;
    }
    76% {
        opacity: 1;
        transform: translateX(0px)translateY(0px)rotate(80deg)scale(0,0);
        animation-timing-function: cubic-bezier(0.34,1.61,0.7,1);
    }
    100% {
        opacity: 1;
        transform: translateX(0px)translateY(0px)rotate(0deg)scale(1,1);
    }
}

#demo_item__image_text {
    color: #000000;
    font-size: 20px;
    font-family: "Harmonia", Arial, sans-serif;
    line-height: 1.55;
    font-weight: 700;
    background-position: center center;
    border-color: transparent;
    border-style: solid;
    animation: demo_item__image_text-anim 4s linear forwards;
    position: relative;
    width: 190px;
    top: 350px;
    left: 120px;
}

#demo_item__image_text.is-paused {
    animation-play-state: paused;
}

@keyframes demo_item__image_text-anim {
    0% {
        opacity: 0;
        transform: translateX(0px)translateY(0px)rotate(0deg)scale(1,1);
        animation-timing-function: linear;
    }
    1% {
        opacity: 0;
        transform: translateX(0px)translateY(10px)rotate(0deg)scale(1,1);
        animation-timing-function: linear;
    }
    76% {
        opacity: 0;
        transform: translateX(0px)translateY(10px)rotate(0deg)scale(1,1);
        animation-timing-function: ease-in-out;
    }
    100% {
        opacity: 1;
        transform: translateX(-7px)translateY(-1px)rotate(0deg)scale(1,1);
    }
}

/* Integration */
#integration_container {
    height: 550px;
    background-color: #f6f6fe;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

#integration_description {
    margin-left: 50px;
}

#integration_description__title {
    color: #000000;
    font-size: 30px;
    font-family: "Harmonia", Arial, sans-serif;
    line-height: 1.55;
    font-weight: 700;
    background-position: center center;
    border-color: transparent;
    border-style: solid;
}

#integration_description__text {
    color: #000000;
    font-size: 20px;
    font-family: "Harmonia", Arial, sans-serif;
    line-height: 1.55;
    font-weight: 400;
    background-position: center center;
    border-color: transparent;
    border-style: solid;
}

#integration_preview__android {
    width: 135px;
    height: auto;
    animation: integration_preview-anim 3s linear forwards;
    grid-area: android;
}

#integration_preview__android.is-paused {
    animation-play-state: paused;
}

@keyframes integration_preview-anim {
    0% {
        opacity: 0;
        transform: translateX(0px)translateY(0px)rotate(0deg)scale(1,1);
        animation-timing-function: linear;
    }
    1% {
        opacity: 0;
        transform: translateX(0px)translateY(0px)rotate(0deg)scale(0,0);
        animation-timing-function: linear;
    }
    84% {
        opacity: 0;
        transform: translateX(0px)translateY(0px)rotate(0deg)scale(0,0);
        animation-timing-function: ease-out;
    }
    100% {
        opacity: 1;
        transform: translateX(0px)translateY(0px)rotate(0deg)scale(1,1);
    }
}

#integration_preview__1c {
    animation: integration_preview-anim 2.9s linear forwards;
    width: 90px;
    height: auto;
    grid-area: c;
}

#integration_preview__1c.is-paused {
    animation-play-state: paused;
}

#integration_preview__wheel {
    animation: integration_preview-anim 2.8s linear forwards;
    width: 60px;
    height: auto;
    grid-area: wheel;
}

#integration_preview__wheel.is-paused {
    animation-play-state: paused;
}

#integration_preview__bitrix {
    width: 75px;
    height: auto;
    grid-area: bitrix;
    animation: integration_preview-anim 2.3s linear forwards;
}

#integration_preview__bitrix.is-paused {
    animation-play-state: paused;
}

#integration_preview__n {
    width: 70px;
    height: auto;
    grid-area: n;
    animation: integration_preview-anim 2.7s linear forwards;
}

#integration_preview__n.is-paused {
    animation-play-state: paused;
}

#integration_preview__amocrm {
    width: 145px;
    height: auto;
    grid-area: amocrm;
    animation: integration_preview-anim 2.4s linear forwards;
}

#integration_preview__amocrm.is-paused {
    animation-play-state: paused;
}

#integration_preview__api {
    width: 65px;
    height: auto;
    grid-area: api;
    animation: integration_preview-anim 2.5s linear forwards;
}

#integration_preview__api.is-paused {
    animation-play-state: paused;
}

#integration_preview__megaplan {
    width: 135px;
    height: auto;
    grid-area: megaplan;
    animation: integration_preview-anim 2.6s linear forwards;
}

#integration_preview__megaplan.is-paused {
    animation-play-state: paused;
}

#integration_preview__pen_container {
    border-radius: 10px;
    background-color: #ffffff;
    background-position: center center;
    border-color: transparent;
    border-style: solid;
    box-shadow: 1px 1px 20px 0px rgba(0, 0, 0, 0.3);
    grid-area: pen;
    height: 180px;
    width: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.integration_preview__line {
    height: 3px;
    width: 60px;
    background-color: #8f8f8f;
    background-position: center center;
}
.rot45 {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    transform: rotate(45deg);
}

.rot90 {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    transform: rotate(90deg);
}

.rot135 {
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    transform: rotate(135deg);
}

#integration_preview__line1_container {
    animation: integration_preview__line-anim 0.5s linear forwards;
    grid-area: line1;
}

#integration_preview__line1_container.is-paused {
    animation-play-state: paused;
}

#integration_preview__line2_container {
    animation: integration_preview__line-anim 0.7s linear forwards;
    grid-area: line2;
}

#integration_preview__line2_container.is-paused {
    animation-play-state: paused;
}

#integration_preview__line3_container {
    animation: integration_preview__line-anim 0.9s linear forwards;
    grid-area: line3;
}

#integration_preview__line3_container.is-paused {
    animation-play-state: paused;
}

#integration_preview__line4_container {
    animation: integration_preview__line-anim 1.9s linear forwards;
    grid-area: line4;
}

#integration_preview__line4_container.is-paused {
    animation-play-state: paused;
}

#integration_preview__line5_container {
    animation: integration_preview__line-anim 1.1s linear forwards;
    grid-area: line5;
}

#integration_preview__line5_container.is-paused {
    animation-play-state: paused;
}

#integration_preview__line6_container {
    animation: integration_preview__line-anim 1.7s linear forwards;
    grid-area: line6;
}

#integration_preview__line6_container.is-paused {
    animation-play-state: paused;
}

#integration_preview__line7_container {
    animation: integration_preview__line-anim 1.5s linear forwards;
    grid-area: line7;
}

#integration_preview__line7_container.is-paused {
    animation-play-state: paused;
}

#integration_preview__line8_container {
    animation: integration_preview__line-anim 1.3s linear forwards;
    grid-area: line8;
}

#integration_preview__line8_container.is-paused {
    animation-play-state: paused;
}

@keyframes integration_preview__line-anim {
    0% {
        opacity: 0;
        transform: translateX(0px)translateY(0px)rotate(0deg)scale(1,1);
        animation-timing-function: linear;
    }
    1% {
        opacity: 0;
        transform: translateX(0px)translateY(0px)rotate(0deg)scale(0,0);
        animation-timing-function: ease-out;
    }
    100% {
        opacity: 1;
        transform: translateX(0px)translateY(0px)rotate(0deg)scale(1,1);
    }
}

#integration_preview {
    display: grid;
    grid-template-columns: repeat(9, 60px);
    grid-template-rows: repeat(9, 60px);
    grid-template-areas:
            ". . . . c . . . ."
            ". android . . line2 . . wheel ."
            ". . line1 . line2 . line3 . ."
            ". . . pen pen pen . . ."
            "bitrix line4 line4 pen pen pen line5 line5 n"
            ". . . pen pen pen . . ."
            ". . line6 . line7 . line8 . ."
            ". amocrm amocrm . line7 . megaplan megaplan ."
            ". . . . api . . . .";
    align-items: center;
    justify-items: center;
}

#integration_preview__pen {
    width: 120px;
    height: auto;
}

#integration_preview__pen_item1 {
    display: flex;
    flex-direction: row;
    align-items: baseline;
}

#integration_preview__cross {
    display: grid;
    grid-template-columns: repeat(1, 20px);
    grid-template-rows: repeat(1, 20px);
}

#cross1 {
    transform: rotate(135deg);
    background-color: #8f8f8f;
    background-position: center center;
    border-color: transparent;
    height: 2px;
    width: 20px;
    grid-row: 1;
    grid-column: 1;
}

#cross2 {
    transform: rotate(45deg);
    background-color: #8f8f8f;
    background-position: center center;
    border-color: transparent;
    height: 2px;
    width: 20px;
    grid-row: 1;
    grid-column: 1;
}
#integration_preview__pen_item2 {
    border-width: 2px;
    border-radius: 0px;
    background-position: center center;
    border-color: #8f8f8f;
    border-style: dashed;
    width: 150px;
}

/* How To */

#howto {
    height: 1125px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#howto_title {
    color: #000000;
    font-size: 40px;
    font-family: "Harmonia", Arial, sans-serif;
    line-height: 1.55;
    font-weight: 500;
    background-position: center center;
    border-color: transparent;
    border-style: solid;
    /* width: 630px; */
    text-align: center;
    margin: 0;
}

.text_underline {
    width: 139px;
    height: 8px;
    background-color: #058dd9;
    position: relative;
    z-index: -1;
    top: -20px;
    left: -98px;
}

#howto_steps {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.howto_step {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 40px;
}

.howto_step__num {
    color: #058dd9;
    font-size: 15px;
    font-family: "Harmonia", Arial, sans-serif;
    line-height: 1.55;
    font-weight: 700;
    background-position: center center;
    border-color: transparent;
    border-style: solid;
    animation: howto_step__num-anim 1.5s linear forwards;
    margin-right: 15px;
}

.is-paused {
    animation-play-state: paused;
}

.howto_step__num.is-paused {
    animation-play-state: paused;
}

.step_expanded {
    flex-direction: column;
    align-items: baseline;
}

#howto_step_main {
    display: flex;
    flex-direction: row;
    align-items: center;
}

#howto_step__link {
    text-decoration: none;
    font-weight: 700;
    color: #fff;
    font-size: 16px;
    font-family: "Harmonia", Arial, sans-serif;
    line-height: 1.55;
    margin-left: 120px;
}

#howto_step__button {
    width: 150px;
    height: 40px;
    background-color: #058dd9;
    display: flex;
    justify-content: center;
    align-items: center;
    border-width: 1px;
    border-radius: 5px;
}

@keyframes howto_step__num-anim {
    0% {
        opacity: 0;
        transform: translateX(0px)translateY(0px)rotate(0deg)scale(1,1);
        animation-timing-function: linear;
    }
    1% {
        opacity: 0;
        transform: translateX(-1000px)translateY(0px)rotate(0deg)scale(1,1);
        animation-timing-function: ease-in-out;
    }
    100% {
        opacity: 1;
        transform: translateX(0px)translateY(0px)rotate(0deg)scale(1,1);
    }
}

.howto_step__circle {
    border-width: 2px;
    border-radius: 3000px;
    background-color: #ffffff;
    background-position: center center;
    border-color: #058dd9;
    border-style: solid;
    width: 16px;
    height: 16px;
}

.howto_step__circle_full {
    border-width: 3px;
    border-radius: 3000px;
    background-color: #058dd9;
    background-position: center center;
    border-color: #ffffff;
    border-style: solid;
    width: 16px;
    height: 16px;
}

.howto_step__text {
    margin-left: 30px;
    margin-top: 20px;
    margin-bottom: 20px;
    color: #000000;
    font-size: 20px;
    font-family: "Harmonia", Arial, sans-serif;
    line-height: 1.2;
    font-weight: 600;
    background-position: center center;
    border-color: transparent;
    border-style: solid;
    animation: howto_step__text-anim 1s linear forwards;
}

.howto_step__text.is-paused {
    animation-play-state: paused;
}

#howto_steps__line {
    position: relative;
    background-color: #d4d4d4;
    background-position: center center;
    border-color: transparent;
    z-index: -1;
    height: 598px;
    width: 2px;
    left: 74px;
    top: 25px;
}

#howto_steps_container {
    display: flex;
    flex-direction: row;
}

#howto_step_description {
    /* position: relative;  */
    color: #000000;
    font-size: 20px;
    font-family: "Harmonia", Arial, sans-serif;
    line-height: 1.2;
    font-weight: 400;
    background-position: center center;
    border-color: transparent;
    border-style: solid;
    margin-left: 115px;
    margin-top: 12px;
    margin-bottom: 12px;
}


@keyframes howto_step__text-anim {
    0% {
        opacity: 0;
        transform: translateX(0px)translateY(0px)rotate(0deg)scale(1,1);
        animation-timing-function: linear;
    }
    1% {
        opacity: 0;
        transform: translateX(0px)translateY(10px)rotate(0deg)scale(1,1);
        animation-timing-function: ease-in-out;
    }
    100% {
        opacity: 1;
        transform: translateX(0px)translateY(0px)rotate(0deg)scale(1,1);
    }
}

#howto_step__play_market {
    width: 170px;
    height: auto;
    animation: howto_step__play_market-anim 1s linear forwards;
    margin-left: 30px;
}

#howto_step__play_market.is-paused {
    animation-play-state: paused;
}

@keyframes howto_step__play_market-anim {
    0% {
        opacity: 0;
        transform: translateX(0px)translateY(0px)rotate(0deg)scale(1,1);
        animation-timing-function: linear;
    }
    1% {
        opacity: 0;
        transform: translateX(0px)translateY(10px)rotate(0deg)scale(1,1);
        animation-timing-function: ease-in-out;
    }
    100% {
        opacity: 1;
        transform: translateX(0px)translateY(0px)rotate(0deg)scale(1,1);
    }
}

#howto_step__1_desc {
    display: flex;
    flex-direction: row;
    align-items: center;
}

#howto_btn__link_order {
    text-decoration: none;
    font-size: 20px;
    font-family: "Harmonia", Arial, sans-serif;
    line-height: 1.55;
    font-weight: 500;
    color: #000000;
    display: flex;
    flex-direction: column;
    width: 250px;
    height: 55px;
    margin-bottom: 40px;
}

#d_btn_order {
    width: 250px;
    height: 55px;
    border: 1px solid #058dd9;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.8s ease-in-out !important;
}

#d_btn_order:hover {
    background-color: #058dd9;
    color: #fff;
    border-radius: 5px;
}

/* Trial */

#trial_item {
    height: 550px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #f6f6fe;
}

#trial_item__description {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#trial_item__preview {
    margin-left: 100px;
}

#trial_item__preview_image {
    animation: trial_item__preview_image-anim 1s linear forwards;
    width: 510px;
    height: auto;
}

#trial_item__preview_image.is-paused {
    animation-play-state: paused;
}

#trial_item__description_title {
    color: #000000;
    font-size: 30px;
    font-family: "Harmonia", Arial, sans-serif;
    line-height: 1.55;
    font-weight: 700;
    background-position: center center;
    border-color: transparent;
    border-style: solid;
}

.trial_item__btn {
    width: 315px;
}

@keyframes trial_item__preview_image-anim {
    0% {
        opacity: 0;
        transform: translateX(0px)translateY(0px)rotate(0deg)scale(1,1);
        animation-timing-function: linear;
    }
    1% {
        opacity: 0;
        transform: translateX(50px)translateY(0px)rotate(0deg)scale(1,1);
        animation-timing-function: ease-out;
    }
    100% {
        opacity: 1;
        transform: translateX(0px)translateY(0px)rotate(0deg)scale(1,1);
    }
}

/* Footer */

footer {
    height: 270px;
    background-color: #058dd9;
    display: flex;
    justify-content: space-between;
    padding: 0 17.5% 0 17.5%;
    width: 65%;
    align-items: center;
}

#footer_links {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 200px;
}

#footer_links__menu {
    display: flex;
    flex-direction: column;
    margin-right: 100px;
    padding-left: 0;
}

#footer_links__menu_list {
    margin: 0;
    padding: 0;
}
.footer_links__menu_list__item {
    list-style-type: none;
}
.footer_links__menu_list__item_link {
    color: #ffffff;
    font-size: 16px;
    font-family: "Harmonia", Arial, sans-serif;
    line-height: 1.55;
    font-weight: 400;
    text-decoration: none;
}

.footer_links__menu_list__item_link:active, .footer_links__menu_list__item_link:hover, .footer_links__menu_list__item_link:focus {
    color: #ffffff;
    text-decoration: none;
    outline: none!important;
    outline-offset: 0!important;
    border-color: transparent;
}

.footer_links__menu_title {
    color: #ffffff;
    font-size: 18px;
    font-family: "Harmonia", Arial, sans-serif;
    line-height: 1.55;
    font-weight: 700;
    background-position: center center;
    border-color: transparent;
    border-style: solid;
    margin-top: 18px;
    margin-bottom: 18px;
}

#footer_links__contacts_list {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 120px;
}

.footer_links__contacts_list__link {
    text-decoration: none;
    color: #ffffff;
    font-size: 16px;
    font-family: "Harmonia", Arial, sans-serif;
    line-height: 1.55;
    font-weight: 400;
    background-position: center center;
    border-color: transparent;
    border-style: solid;
}

.footer_links__contacts_list__link:active, .footer_links__contacts_list__link:hover, .footer_links__contacts_list__link:focus {
    color: #ffffff;
    text-decoration: none;
    outline: none!important;
    outline-offset: 0!important;
    border-color: transparent;
}

#footer_buttons__copyright_image {
    width: 150px;
    height: auto;
}

#footer_button {
    border-width: 1px;
    border-radius: 5px;
    background-position: center center;
    border-color: #ffffff;
    border-style: solid;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, border-color 0.2s ease-in-out;
    width: 200px;
    height: 55px;
    display: flex;
    justify-content: center;

}

#footer_button__text {
    margin: auto;
}

#footer_button__link {
    color: #ffffff;
    font-size: 15px;
    font-family: "Harmonia", Arial, sans-serif;
    line-height: 1.55;
    font-weight: 700;
    width: 200px;
    height: 55px;
    text-decoration: none;
    padding-top: 15px;
}

#footer_buttons {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-end;
    padding-top: 50px;
    height: 200px;

}

#footer_buttons__copyright_text {
    text-decoration: none;
    color: rgb(255, 255, 255) !important;
    border-bottom: 1px solid rgb(255, 255, 255);
    box-shadow: inset 0px 0px 0px 0px rgb(255, 255, 255);
    -webkit-box-shadow: inset 0px 0px 0px 0px rgb(255, 255, 255);
    -moz-box-shadow: inset 0px -0px 0px 0px rgb(255, 255, 255);
    font-size: 12px;
    font-family: "Harmonia", Arial, sans-serif;
    line-height: 1.55;
    font-weight: 700;
    background-position: center center;
    border-color: transparent;
}

#footer_buttons__copyright {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

/* Mobile menu */
#showmenu {
    display: none
}
label {
    cursor: pointer;
}

.nav {
    user-select: none;
    display: none;

}

.nav__showmenu {
    position: relative;
    z-index: 3;
    display: inline-block;
    margin-left: -10px;
    padding: 5px 10px 10px;
    top: 2px;
    font-size: 28px;

}

.menu, .menu__submenu{
    list-style: none;
    padding: 0;
    color: #fff;
    overflow: hidden;
  }

.menu {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    width: 260px;
    margin: 0;
    padding: 17px 0;
    text-transform: uppercase;
    line-height: 2em;
    background-color: #058dd9;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    transform: translateX(100%);
    transition: transform .3s;
    will-change: transform;
    backface-visibility: hidden;
    padding-top: 70px;
    padding-right: 20px;

}

.menu_nav {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
}

.menu__submenu {
    font-size: .8em;
    background-color: #ddd;
    max-height: 0;
    transition: max-height .3s ease-in-out;
}

.menu__showsub {
    position: absolute;
    right: 0;
    margin-top: -34px;
    padding: 10px;
    line-height: normal;
}

#menu_logo {
    max-width: 120px;
    height: auto;
    padding-bottom: 20px;
}

.menu__item {
    display: block;
    text-align: right;
    text-decoration: none;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    font-family: 'Harmonia';
    padding-bottom: 15px;
}

.menu__submenu .menu__item {
    color: #058dd9;
}

.menu_button {
    border-width: 1px;
    border-radius: 5px;
    background-position: center center;
    border-color: #ffffff;
    border-style: solid;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, border-color 0.2s ease-in-out;
    width: 100px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;

}

.menu_button__link {
    color: #ffffff;
    font-size: 15px;
    font-family: "Harmonia", Arial, sans-serif;
    line-height: 1.55;
    font-weight: 700;
    width: 100px;
    height: 45px;
    text-decoration: none;
}

.mobile-logout {
    background-color: transparent;
}

.nav__check:checked~.nav__back {
    background-color: rgba(0,0,0,.2);
    bottom: 0;
}

.menu__item:hover,.nav__showmenu:hover,.menu__showsub:hover {
    opacity: 0.7;
}
.menu::-webkit-scrollbar {
    display: none;
}
.nav__check:checked+.nav__showmenu {
    color:#fff;
}
.menu__check:checked~.menu__submenu {
    max-height: 100vh;
}
.menu__check:checked+.menu__showsub {
    transform: rotate(180deg);
}
.nav__check:checked~.menu {
    transform: translateX(0);
}


/* Policy */
#policy {
    /* width: 1180px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 90px;
}

#policy_title {
    color: #058dd9;
    font-size: 46px;
    font-family: "Harmonia", Arial, sans-serif;
    line-height: 1.55;
    font-weight: 700;
    background-position: center center;
    border-color: transparent;
    border-style: solid;

}

.policy_link {
    color: rgb(5, 141, 217) !important;
    text-decoration: none;
    border-bottom: 0px solid;
    box-shadow: inset 0px -0px 0px 0px;
    -webkit-box-shadow: inset 0px -0px 0px 0px;
    -moz-box-shadow: inset 0px -0px 0px 0px;
}

.policy_text {
    color: #000000;
    font-size: 16px;
    font-family: "Harmonia", Arial, sans-serif;
    line-height: 1.55;
    font-weight: 600;
    background-position: center center;
    border-color: transparent;
    border-style: solid;
    width: 1180px;
}

/* Test */

#test {
    padding-top: 90px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
#test_table {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 1px solid #000000;
    border-radius: 5px;
    margin-top: 50px;
    /* width: 1171px; */
}

.test_table__row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 80px;
    /* outline: 1px solid #000000; */
}

.test_table__row_item {
    width: 8vw;
    /* height: 50px; */
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #000000;
}

.test_item_button {
    width: 375px;
}
.test_table__green {
    background-color: #ebfce9;
}

.test_table__red {
    background-color: #ffeae6;
}
.test_table__grey {
    /* background-color: #e7e7e7; */
}

#test_table__row_item__photo {
    width: 100px;
    height: auto;
}

.test_table__expand {
    height: 200px;
}

#map {
    width: 8vw;
    height: 195px;
}

.test_table__row_item__text {
    color: #000000;
    font-size: 1vw;
    font-family: "Harmonia", Arial, sans-serif;
    line-height: 1.55;
    font-weight: 400;
    background-position: center center;
    border-color: transparent;
    border-style: solid;
    text-align: center;
    padding: 0 20px 0 20px;
}

#test_buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;;
}

#test_button_back__link {
    color: #058dd9;
    font-size: 16px;
    font-family: "Harmonia", Arial, sans-serif;
    line-height: 1.55;
    font-weight: 600;
    text-decoration: none;
    width: 200px;
    height: 55px;
}

.test_item__btn {
    margin-top: 0px;
    margin-left: 50px;
}

#test_button_back {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 55px;
    border-width: 1px;
    border-radius: 5px;
    background-position: center center;
    border-color: #058dd9;
    border-style: solid;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}

#test_buttons {
    margin-top: 50px;
}

/* Modal */

.modal-login {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0,0,0,0.5);
    z-index: -1;
    opacity: 0;
    -webkit-transition: opacity 200ms ease-in;
    -moz-transition: opacity 200ms ease-in;
    transition: opacity 200ms ease-in;
    pointer-events: none;
    margin: 0;
    padding: 0;
}

.modal-login:target {
    opacity: 1;
    z-index: 1050;
    pointer-events: auto;
    overflow-y: auto;
    display: block;
}

.modal-login-header:before, .modal-login-header:after, .modal-login-footer:before, .modal-login-footer:after {
    content: none;
}

.modal-login-dialog {
    position: relative;
    width: auto;
    margin: 10px;
}
@media (min-width: 576px) {
  .modal-login-dialog {
      max-width: 500px;
      margin: 30px auto;
  }
}

.modal-login-content {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    background-color: #fff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: .3rem;
    outline: 0;
}
@media (min-width: 768px) {
  .modal-login-content {
      -webkit-box-shadow: 0 5px 15px rgba(0,0,0,.5);
      box-shadow: 0 5px 15px rgba(0,0,0,.5);
  }
}

.modal-login-header {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 15px;
    border-bottom: 1px solid #eceeef;
}
.modal-login-title {
    margin-top: 0;
    margin-bottom: 0;
    line-height: 1.5;
    font-size: 1.25rem;
    font-weight: 500;
}

.close {
    float: right;
    font-family: sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
    text-decoration: none;
}

.close:focus, .close:hover {
    color: #000;
    text-decoration: none;
    cursor: pointer;
    opacity: .75;
}

.modal-login_body {
  position: relative;
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 15px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.modal-login_body__title {
    font-family: "Harmonia", Arial, sans-serif;
    font-weight: 600;
    color: #000;
    font-size: 36px;
    line-height: 1.23;
    text-align: center;
}

.modal-login_body__text {
    text-align: center;
    font-family: "Harmonia", Arial, sans-serif;
    font-weight: 300;
    color: #000;
    font-size: 16px;
    line-height: 1.55;
    margin-top: 16px;
    margin-bottom: 16px;
}

.form-label.modal_body__text {
    margin-top: 0;
    margin-bottom: 0;
}

#modal-login_body__link {
    color: #000;
    cursor: pointer;
    text-decoration: underline;
}

.modal-login_input {
    color: #000000;
    border: 1px solid #c9c9c9;
    border-radius: 5px;
    margin: 0;
    font-family: "Harmonia", Arial, sans-serif;
    font-size: 100%;
    height: 60px;
    padding: 0 20px;
    font-size: 16px;
    line-height: 1.33;
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    outline: none;
    -webkit-appearance: none;
    margin-bottom: 25px;
}

.modal-login_input:focus {
    border-color: #058dd9;
}

#modal-login_form {
    display: flex;
    flex-direction: column;
}

.modal-login-submit {
    color: #ffffff;
    background-color: #058dd9;
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    display: block;
    text-align: center;
    height: 100%;
    width: 100%;
    margin-top: 25px;
    height: 54px;
    font-family: "Harmonia", Arial, sans-serif;
    text-align: center;
    height: 60px;
    border: 0 none;
    font-size: 16px;
    padding-left: 60px;
    padding-right: 60px;
    -webkit-appearance: none;
    font-weight: 700;
    white-space: nowrap;
    background-image: none;
    cursor: pointer;
    margin: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    outline: none;
}


.grecaptcha-badge { visibility: hidden; }

.site-login .row.jumbotron {
    padding: 60px;
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    margin: 0;
    background-color: #eeeeee;
}

.site-login .row .col-lg-12 {
    padding: 0;
}

.site-login p.error-message.text-danger {
    font-size: 16px;
    margin-bottom: 0;
}

.site-login p.password-reset-a {
    font-size: 16px;
}

.site-login p.error-message.text-danger a {
    font-size: 16px;
    margin-bottom: 0;
}

.site-login p.error-message.text-danger p {
    font-size: 16px;
    margin-bottom: 0;
}

.site-login input {
    margin-bottom: 15px;
}

.site-login label.check, .site-login .checkbox-group .form-label {
    left: 0;
    float: none;
}

.site-login label.check {
    padding-left: 0;
    padding-right: 5px;
    top: -6px !important
}

.site-login button#sign-in {
    margin-top: 5px;
    position: inherit;
    bottom: -35px;
}

/* Large desktops and laptops */
@media (min-width: 1200px) and (max-width: 1600px) {
    /* #description_preview {
        grid-template-columns: repeat(7, 60px);
        grid-template-rows: repeat(7, 60px);
    } */

    #demo_item__sign {
        top: 350px;
    }

    #demo_item__arrow_left {
        top: 90px;
        left: 320px;

    }

    #demo_item__sign_rot {
        transform: rotate(90deg);
    }

    #demo_item__image_text {
        top: 520px;
        left: -170px;
    }

    /* #description_preview {
        grid-template-columns: repeat(7, 60px);
        grid-template-rows: repeat(7, 60px);
    }
    #description_preview__law {
        width: 340px;
    }
    #description_preview__bg {
        position: absolute;
        left: 0px;
        top: 488px;
        width: 690px;
    }
    #description_preview__pen {
        width: 100px;
        top: -30px;
    }
    #description_preview__loop {
        top: -100px;
        left: 80px;
        width: 100px;
    }
    #description_preview__shield {
        top: -100px;
        right: 30px;
        width: 100px;
    } */

/*
    #description_preview__pc {
        width: 150px;
    } */
}

/* Portrait tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {
    .nav {
        user-select: none;
        display: block;

    }
    menu {
        display: none;
    }

    /* Description */
    #description {
        /* flex-direction: column;
        background-repeat: no-repeat;
        background-position-y: bottom;
        justify-content: space-evenly;
        background-size: auto */

    }
    #decription_info {
        margin-top: 90px;
    }


    #description_text {
        font-size: 18px;
    }

    #description_preview {
        grid-template-columns: repeat(7, 60px);
        grid-template-rows: repeat(7, 60px);
    }
    #description_preview__law {
        width: 340px;
    }
    #description_buttons {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    #d_btn_buy {
        align-self: flex-end;
    }
    #description_preview__bg {
        position: absolute;
        left: 0px;
        top: 488px;
        width: 690px;
    }
    #description_preview__pen {
        width: 100px;
        top: -30px;
    }
    #description_preview__loop {
        top: -100px;
        left: 80px;
        width: 100px;
    }
    #description_preview__shield {
        top: -100px;
        right: 30px;
        width: 100px;
    }


    #description_preview__pc {
        width: 150px;
        top: 30px;
    }

    /* Benefits */

    .benefits_item {
        flex-direction: row;
        /* justify-content: flex-start; */
        /* height: 500px; */
    }

    /* #benefits_item-invert {
        flex-direction: row;
    } */

    .benefits_item__preview_image {
        width: 360px;
    }

    .benefits_item__preview_number {
        width: 100px;
    }

    .benefits_item__preview {
        background-size: contain;
        /* width: 360px; */
        height: auto;
    }

    .benefits_item__preview_number-left {
        bottom: 70px;
        left: 0px;
    }

    .benefits_item__preview_image-left {
        left: 50px;
    }

    .section_title {
        font-size: 40px;
        text-align: center;
    }

    .benefits_item__description_title {
        font-size: 38px;
    }

    .benefits_item__description_text {
        font-size: 22px;
        width: 400px;
    }
    .pr20 {
        padding-right: 0px;
    }
    .pl20 {
        padding-left: 0px;
    }

    .benefits_item__preview_number-right {
        bottom: 50px;
        right: 0px;
    }

    .benefits_item__preview_image-right {
        top: 80px;
        right: 100px;
    }

    .benefits_item__description {
        padding: 0 20px 0 20px;
        width: 400px;
    }

    .txt-aling_right {
        text-align: left;
    }


    /* Usage */
    #usage_items {
        flex-direction: column;
    }

    #usage_container {
        height: 3800px;
    }

    .mr30 {
        margin-right: 0px;
        margin-bottom: 30px;
    }
    .usage_item {
        height: 800px;
        width: 500px;
    }
    .usage_button__text {
        color: #000000;
        font-size: 14px;
        font-family: "Harmonia", Arial, sans-serif;
        line-height: 1.55;
        font-weight: 500;
    }

    .usage_button {
        width: 315px;
        height: 55px;
    }

    .usage_button__image {
        width: 45px;
    }

    .usage_item__preview {
        width: 140px;
    }

    .usage_item__title {
        font-size: 40px;
    }

    .usage_item__text {
        font-size: 28px;
    }

    /* Demo */

    #demo {
        height: 1580px;
    }
    #demo_items {
        flex-direction: column;
    }

    #demo_item__sign {
        width: 185px;
        left: 50px;
        top: 200px;
    }
    #demo_item__sign_rot {
        transform: rotate(90deg);
    }

    .demo_item__title {
        font-size: 25px;
    }

    #demo_item__arrow_left {
        top: 60px;
        left: 230px;
    }

    #demo_item__image_text {
        left: -200px;
        top: 370px;
    }

    #demo_item__images {
        grid-template-columns: repeat(5, 58px);
        grid-template-rows: repeat(3, 87,8px);
    }

    .demo_item__image {
        width: 290px;
    }

    #integration_container {
        flex-direction: column-reverse;
        height: 1250px;
    }

    .cloud_top {
        height: 40px;
    }

    .cloud_bottom {
        height: 40px;
    }

    /* #integration_preview__android {
        width: 105px;
    }

    #integration_preview__1c {
        width: 70px;
    }

    #integration_preview__wheel {
        width: 40px;
    }

    #integration_preview__bitrix {
        width: 55px;
    }

    #integration_preview__n {
        width: 50px;
    }

    #integration_preview__amocrm {
        width: 75px;
    }

    #integration_preview__api {
        width: 45px;
    }

    #integration_preview__megaplan {
        width: 75px;
    }

    #integration_preview__pen_container {
        height: 110px;
        width: 110px;
    }

    .integration_preview__line {
        width: 30px;
    }

    #integration_preview__pen {
        width: 50px;
    }

    #cross1 {
        width: 20px;
    }

    #integration_preview__pen_item2 {
        width: 90px;
    } */

    #integration_preview {
        /* grid-template-columns: repeat(9, 38px);
        grid-template-rows: repeat(9, 38px);
        */
        margin-top: 40px;
    }

    #integration_description__title {
        font-size: 35px;
        text-align: center;
    }

    #integration_description {
        margin-left: 0px;
    }

    #integration_description__text {
        text-align: center;
        font-size: 25px;
    }

    /* How To */

    #howto {
        height: 1373px;
    }
    #howto_title {
        font-size: 38px;
    }

    .howto_step__text {
        font-size: 20px;
        margin-left: 20px;
        width: 300px;
    }

    #howto_step__play_market {
        width: 130px;
        margin-left: 22px;
    }

    #howto_step_description {
        font-size: 12px;
        width: 200px;
        top: -20px;
        position: relative;
        left: -5px;
    }

    #howto_steps__line {
        height: 820px;
        top: 80px;
        left: 83px;
    }
    .howto_step {
        justify-content: center;
    }

    .step_expanded {
        align-items: center;
    }

    .howto_steps__button {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        font-family: "Harmonia", Arial, sans-serif;
        line-height: 1.55;
        font-weight: 500;
        border-width: 1px;
        border-radius: 5px;
        opacity: 0.4;
    }

    #howto_step__button {
        position: relative;
        left: -35px;
    }

    #howto_step__link {
        margin-left: 20px;
    }

    .howto_step__num {
        font-size: 18px;
    }

    .text_underline {
        width: 125px;
        height: 5px;
        top: -16px;
        left: -95px;
    }

    #howto_step__1_desc {
        flex-direction: column;
        margin-top: 40px;
        align-items: end;
    }

    .howto_btn {
        width: 250px;
        height: 55px;
    }

    #howto_btn__link_order {
        width: 250px;
        height: 55px;

        font-size: 20px;
        font-family: "Harmonia", Arial, sans-serif;
        line-height: 1.55;
        font-weight: 500;
        border-width: 1px;
        border-radius: 5px;
    }

    #d_btn_order {
        width: 250px;
        height: 55px;
    }
    /* trial */
    #trial_item {
        flex-direction: column-reverse;
        height: 850px;
    }

    #trial_item__description_title {
        font-size: 20px;
        text-align: center;
    }

    #trial_item__preview_image {
        width: 500px;
        padding-left: 30px;
    }

    #trial_item__preview {

        margin-left: 0px;
    }

    .trial_item_button {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .usage_button__text {
        font-size: 20px;
        font-family: "Harmonia", Arial, sans-serif;
        line-height: 1.55;
        font-weight: 500;
    }

    .usage_button__link {
        display: flex;
        justify-content: center;
    }
    /* Footer */
    footer {
        flex-direction: column;
        height: 530px;
        align-items: center;
        justify-content: center;
    }

    #footer_links {
        align-items: baseline;
    }

    #footer_links__menu {
        margin-right: 200px;
        padding: 0;
    }
    .footer_links__menu_list__item {
        width: 138px;
    }
    .footer_links__menu_list__item_link {
        font-size: 18px;
    }

    .footer_links__contacts_list__link {
        font-size: 18px;
    }

    .footer_links__menu_title {
        font-size: 20px;
    }

    #footer_buttons {
        align-items: center;
    }

    #footer_buttons__copyright {
        align-items: center;
    }

    #footer_button__link {
        margin-bottom: 20px;
        width: 280px;
        height: 55px;
    }

    #footer_button {
        width: 280px;
        height: 55px;
    }

    /* Policy */
    #policy_title {
        font-size: 18px;
        text-align: center;
    }
    .policy_text {
        font-size: 12px;
        width: 310px;
    }

    /* test */

    #test_table {
        flex-direction: row;
    }

    .test_table__row {
        flex-direction: column;
        height: auto;
    }

    .test_table__row_item__text {
        font-size: inherit;
    }

    .test_table__row_item {
        width: auto;
        height: 80px;
    }

    #map {
        height: 80px;
        width: 8vw;
    }

    #test_table__row_item__photo {
        width: 40px;
    }

    #test_buttons {
        flex-direction: column;
    }

    #test {
        height: auto;
    }

    .test_item__btn {
        width: 270px;
    }

    .test_button__text {
        font-size: 14px;
    }

    .test_item__btn {
        margin-left: 0px;
        margin-top: 30px;
        /* margin-bottom: 30px; */
    }

    #test_button_back {
        width: 270px;
    }

    #test_button_back__link {
        width: 270px;
    }
}

/* Portrait tablets and small desktops */
@media (min-width: 768px) and (max-width: 992px) {
    .nav {
        user-select: none;
        display: block;

    }
    menu {
        display: none;
    }

    /* Description */
    #description {
        /* flex-direction: column; */
        background-repeat: no-repeat;
        background-position-y: bottom;
        justify-content: space-evenly;
        background-size: auto

    }
    #decription_info {
        margin-top: 90px;
    }
    #description_title {
        font-size: 30px;
    }

    /*
    #description_text {
        font-size: 12px;
    } */
    /* .description_btn {
        width: 160px;
        height: 35px;
    }
    #description_btn__link_get {
        font-size: 14px;
    }
    #description_btn__link_buy {
        font-size: 14px;
    } */
    #description_preview {
        grid-template-columns: repeat(7, 60px);
        grid-template-rows: repeat(7, 60px);
    }
    #description_preview__law {
        width: 320px;
    }
    #description_buttons {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    #d_btn_buy {
        align-self: flex-end;
    }
    #description_preview__bg {
        position: absolute;
        left: 0px;
        top: 488px;
        width: 690px;
    }
    #description_preview__pen {
        width: 100px;
        top: -30px;
    }
    #description_preview__loop {
        top: -100px;
        left: 80px;
        width: 100px;
    }
    #description_preview__shield {
        top: -100px;
        right: 30px;
        width: 100px;
    }


    #description_preview__pc {
        width: 150px;
        top: inherit
    }

    /* Benefits */

    .benefits_item {
        flex-direction: row;
        /* justify-content: flex-start; */
        height: 400px;
    }

    /* #benefits_item-invert {
        flex-direction: row;
    } */

    .benefits_item__preview_image {
        width: 160px;
    }

    .benefits_item__preview_number {
        width: 100px;
    }

    .benefits_item__preview {
        background-size: contain;
        height: 240px;
    }

    .benefits_item__preview_number-left {
        bottom: 70px;
        left: 0px;
    }

    .benefits_item__preview_image-left {
        left: 50px;
    }

    .section_title {
        font-size: 30px;
        text-align: center;
    }

    .benefits_item__description_title {
        font-size: 25px;
    }

    .benefits_item__description_text {
        font-size: 18px;
        width: 400px;
    }
    .pr20 {
        padding-right: 0px;
    }
    .pl20 {
        padding-left: 0px;
    }

    .benefits_item__preview_number-right {
        bottom: 50px;
        right: 0px;
    }

    .benefits_item__preview_image-right {
        top: 80px;
        right: 100px;
    }

    .benefits_item__description {
        padding: 0 20px 0 20px;
    }

    .txt-aling_right {
        text-align: right;
    }

    /* Usage */
    #usage_items {
        flex-direction: column;
    }

    #usage_container {
        height: 2350px;
    }

    .mr30 {
        margin-right: 0px;
        margin-bottom: 30px;
    }

    .usage_button__text {
        color: #000000;
        font-size: 14px;
        font-family: "Harmonia", Arial, sans-serif;
        line-height: 1.55;
        font-weight: 500;
    }

    .usage_button {
        width: 315px;
        height: 55px;
    }

    .usage_button__image {
        width: 45px;
    }

    /* Demo */

    #demo {
        height: 1580px;
    }
    #demo_items {
        flex-direction: column;
    }

    #demo_item__sign {
        width: 185px;
        left: 50px;
        top: 200px;
    }
    #demo_item__sign_rot {
        transform: rotate(90deg);
    }

    .demo_item__title {
        font-size: 25px;
    }

    #demo_item__arrow_left {
        top: 60px;
        left: 230px;
    }

    #demo_item__image_text {
        left: -200px;
        top: 370px;
    }

    #demo_item__images {
        grid-template-columns: repeat(5, 58px);
        grid-template-rows: repeat(3, 87,8px);
    }

    .demo_item__image {
        width: 290px;
    }

    #integration_container {
        flex-direction: column-reverse;
        height: 950px;
    }

    .cloud_top {
        height: 40px;
    }

    .cloud_bottom {
        height: 40px;
    }

    /* #integration_preview__android {
        width: 105px;
    }

    #integration_preview__1c {
        width: 70px;
    }

    #integration_preview__wheel {
        width: 40px;
    }

    #integration_preview__bitrix {
        width: 55px;
    }

    #integration_preview__n {
        width: 50px;
    }

    #integration_preview__amocrm {
        width: 75px;
    }

    #integration_preview__api {
        width: 45px;
    }

    #integration_preview__megaplan {
        width: 75px;
    }

    #integration_preview__pen_container {
        height: 110px;
        width: 110px;
    }

    .integration_preview__line {
        width: 30px;
    }

    #integration_preview__pen {
        width: 50px;
    }

    #cross1 {
        width: 20px;
    }

    #integration_preview__pen_item2 {
        width: 90px;
    } */

    #integration_preview {
        /* grid-template-columns: repeat(9, 38px);
        grid-template-rows: repeat(9, 38px);
        */
        margin-top: 40px;
    }

    #integration_description__title {
        font-size: 25px;
        text-align: center;
    }

    #integration_description {
        margin-left: 0px;
    }

    #integration_description__text {
        text-align: center;
        font-size: 18px;
    }

    /* How To */

    #howto {
        height: 1373px;
    }
    #howto_title {
        font-size: 38px;
    }

    .howto_step__text {
        font-size: 20px;
        margin-left: 20px;
        width: 300px;
    }

    #howto_step__play_market {
        width: 130px;
        margin-left: 22px;
    }

    #howto_step_description {
        font-size: 12px;
        width: 200px;
        top: -20px;
        position: relative;
        left: -5px;
    }

    #howto_steps__line {
        height: 820px;
        top: 80px;
        left: 83px;
    }
    .howto_step {
        justify-content: center;
    }

    .step_expanded {
        align-items: center;
    }

    .howto_steps__button {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        font-family: "Harmonia", Arial, sans-serif;
        line-height: 1.55;
        font-weight: 500;
        border-width: 1px;
        border-radius: 5px;
        opacity: 0.4;
    }

    #howto_step__button {
        position: relative;
        left: -35px;
    }

    #howto_step__link {
        margin-left: 20px;
    }

    .howto_step__num {
        font-size: 18px;
    }

    .text_underline {
        width: 125px;
        height: 5px;
        top: -16px;
        left: -95px;
    }

    #howto_step__1_desc {
        flex-direction: column;
        margin-top: 40px;
        align-items: end;
    }

    .howto_btn {
        width: 250px;
        height: 55px;
    }

    #howto_btn__link_order {
        width: 250px;
        height: 55px;

        font-size: 20px;
        font-family: "Harmonia", Arial, sans-serif;
        line-height: 1.55;
        font-weight: 500;
        border-width: 1px;
        border-radius: 5px;
    }

    #d_btn_order {
        width: 250px;
        height: 55px;
    }
    /* trial */
    #trial_item {
        flex-direction: column-reverse;
        height: 850px;
    }

    #trial_item__description_title {
        font-size: 20px;
        text-align: center;
    }

    #trial_item__preview_image {
        width: 500px;
        padding-left: 30px;
    }

    #trial_item__preview {

        margin-left: 0px;
    }

    .trial_item_button {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .usage_button__text {
        font-size: 20px;
        font-family: "Harmonia", Arial, sans-serif;
        line-height: 1.55;
        font-weight: 500;
    }

    .usage_button__link {
        display: flex;
        justify-content: center;
    }
    /* Footer */
    footer {
        flex-direction: column;
        height: 430px;
        align-items: center;
    }

    #footer_links__menu {
        margin-right: 200px;
        padding: 0;
    }

    #footer_links {
        align-items: baseline;
    }
    .footer_links__menu_list__item {
        width: 138px;
    }
    .footer_links__menu_list__item_link {
        font-size: 18px;
    }

    .footer_links__contacts_list__link {
        font-size: 18px;
    }

    .footer_links__menu_title {
        font-size: 20px;
    }

    #footer_buttons {
        align-items: center;
    }

    #footer_buttons__copyright {
        align-items: center;
    }

    #footer_button__link {
        margin-bottom: 20px;
        width: 280px;
        height: 55px;
    }

    #footer_button {
        width: 280px;
        height: 55px;
    }

    /* Policy */
    #policy_title {
        font-size: 18px;
        text-align: center;
    }
    .policy_text {
        font-size: 12px;
        width: 310px;
    }

    /* test */

    #test_table {
        flex-direction: row;
    }

    .test_table__row {
        flex-direction: column;
        height: auto;
    }

    .test_table__row_item__text {
        font-size: inherit;
    }

    .test_table__row_item {
        width: 100px;
        height: 80px;
    }

    #map {
        height: 80px;
        width: 8vw;
    }

    #test_table__row_item__photo {
        width: 40px;
    }

    #test_buttons {
        flex-direction: column;
    }

    #test {
        height: auto;
    }

    .test_item__btn {
        width: 270px;
    }

    .test_button__text {
        font-size: 14px;
    }

    .test_item__btn {
        margin-left: 0px;
        margin-top: 30px;
        /* margin-bottom: 30px; */
    }

    #test_button_back {
        width: 270px;
    }

    #test_button_back__link {
        width: 270px;
    }
}

@media (min-width: 768px) {
    .site-login .row .col-lg-12 {
        min-width: 500px;
        min-height: 320px;
    }
}

/* Landscape phones and portrait tablets */
@media (max-width: 767px) {
    menu {
        display: none;
    }

    .nav {
        user-select: none;
        display: block;

    }
    menu {
        display: none;
    }
    header {
        padding: 0 5% 0 5%;
        justify-content: space-between;
        width: 90%;
        padding: 0px;

    }

    /* Description */
    #description {
        /* flex-direction: column; */
        background-repeat: no-repeat;
        background-position-y: bottom;
        justify-content: space-evenly;
        background-size: 900px;

    }

    #decription_info {
        margin-top: 90px;
    }
    #description_title {
        font-size: 20px;
    }
    #description_text {
        font-size: 12px;
    }
    .description_btn {
        width: 160px;
        height: 35px;
    }
    #description_btn__link_get {
        font-size: 14px;
        margin-bottom: 0px;
    }
    #description_btn__link_buy {
        font-size: 14px;
        margin-bottom: 0px;
        flex-direction: row;
    }
    #description_preview {
        grid-template-columns: repeat(7, 40px);
        grid-template-rows: repeat(7, 40px);
    }
    #description_preview__law {
        width: 200px;
    }
    #description_buttons {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    #d_btn_buy {
        align-self: flex-end;
    }
    #description_preview__bg {
        position: absolute;
        left: 0px;
        top: 488px;
        width: 690px;
    }
    #description_preview__pen {
        width: 50px;
        top: -30px;
        left: -50px;
    }
    #description_preview__loop {
        top: -100px;
        left: 80px;
        width: 50px;
    }
    #description_preview__shield {
        top: -100px;
        right: 30px;
        width: 50px;
    }
    #description_preview__pc {
        width: 80px;
        top: -40px;
        left: 30px;

    }

    /* Benefits */

    .benefits_item {
        flex-direction: column;
        justify-content: flex-start;
    }

    .benefits_item__preview_image {
        width: 160px;
    }

    .benefits_item__preview_number {
        width: 100px;
    }

    .benefits_item__preview {
        background-size: contain;
        height: 240px;
    }

    .benefits_item__preview_number-left {
        bottom: 70px;
        left: 0px;
    }

    .benefits_item__preview_image-left {
        left: 50px;
    }

    .section_title {
        font-size: 30px;
        text-align: center;
    }

    .benefits_item__description_title {
        font-size: 20px;
    }

    .benefits_item__description_text {
        font-size: 15px;
    }
    .pr20 {
        padding-right: 0px;
    }
    .pl20 {
        padding-left: 0px;
    }

    #benefits_item-invert {
        flex-direction: column-reverse;
    }

    .benefits_item__preview_number-right {
        bottom: 50px;
        right: 0px;
    }

    .benefits_item__preview_image-right {
        top: 80px;
        right: 100px;
    }

    .benefits_item__description {
        padding: 0 20px 0 20px;
    }

    .txt-aling_right {
        text-align: right;
    }

    /* Usage */
    #usage_items {
        flex-direction: column;
    }

    #usage_container {
        height: 2350px;
    }

    .mr30 {
        margin-right: 0px;
        margin-bottom: 30px;
    }

    .usage_button__text {
        color: #000000;
        font-size: 14px;
        font-family: "Harmonia", Arial, sans-serif;
        line-height: 1.55;
        font-weight: 500;
    }

    .usage_button {
        width: 315px;
        height: 55px;
    }

    .usage_button__image {
        width: 45px;
    }

    /* Demo */

    #demo {
        height: 1380px;
    }
    #demo_items {
        flex-direction: column;
    }

    #demo_item__sign {
        width: 185px;
        left: 50px;
        top: 200px;
    }
    #demo_item__sign_rot {
        transform: rotate(90deg);
    }

    #demo_item__arrow_left {
        top: 60px;
        left: 230px;
    }

    #demo_item__image_text {
        left: -200px;
        top: 370px;
    }

    #demo_item__images {
        grid-template-columns: repeat(5, 58px);
        grid-template-rows: repeat(3, 87,8px);
    }

    .demo_item__image {
        width: 290px;
    }

    #integration_container {
        flex-direction: column-reverse;
        height: 950px;
    }

    .cloud_top {
        height: 40px;
    }

    .cloud_bottom {
        height: 40px;
    }

    #integration_preview__android {
        width: 105px;
    }

    #integration_preview__1c {
        width: 70px;
    }

    #integration_preview__wheel {
        width: 40px;
    }

    #integration_preview__bitrix {
        width: 55px;
    }

    #integration_preview__n {
        width: 50px;
    }

    #integration_preview__amocrm {
        width: 75px;
    }

    #integration_preview__api {
        width: 45px;
    }

    #integration_preview__megaplan {
        width: 75px;
    }

    #integration_preview__pen_container {
        height: 110px;
        width: 110px;
    }

    .integration_preview__line {
        width: 30px;
    }

    #integration_preview__pen {
        width: 50px;
    }

    #cross1 {
        width: 20px;
    }

    #integration_preview__pen_item2 {
        width: 90px;
    }

    #integration_preview {
        grid-template-columns: repeat(9, 38px);
        grid-template-rows: repeat(9, 38px);
        margin-top: 40px;
    }

    #integration_description__title {
        font-size: 20px;
        text-align: center;
    }

    #integration_description {
        margin-left: 0px;
    }

    #integration_description__text {
        text-align: center;
        font-size: 14px;
    }

    /* How To */

    #howto_title {
        font-size: 20px;
    }

    .howto_step__text {
        font-size: 15px;
        margin-left: 20px;
        width: 200px;
    }

    #howto_step__play_market {
        width: 130px;
        margin-left: 22px;
    }

    #howto_step_description {
        font-size: 12px;
        width: 200px;
        top: -20px;
        position: relative;
        left: -5px;
    }

    #howto_steps__line {
        height: 720px;
        left: 79px;
        top: 80px;
    }
    .howto_step {
        justify-content: center;
    }

    .step_expanded {
        align-items: center;
    }

    .howto_steps__button {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        font-family: "Harmonia", Arial, sans-serif;
        line-height: 1.55;
        font-weight: 500;
        border-width: 1px;
        border-radius: 5px;
        opacity: 0.4;
    }

    #howto_step__button {
        position: relative;
        left: -35px;
    }

    .text_underline {
        width: 70px;
        height: 5px;
        top: -12px;
        left: -50px;
    }

    #howto_step__1_desc {
        flex-direction: column;
        margin-top: 40px;
        align-items: end;
    }

    .howto_btn {
        width: 250px;
        height: 55px;
    }

    #howto_btn__link_order {
        width: 250px;
        height: 55px;

        font-size: 20px;
        font-family: "Harmonia", Arial, sans-serif;
        line-height: 1.55;
        font-weight: 500;
        border-width: 1px;
        border-radius: 5px;
    }

    #d_btn_order {
        width: 250px;
        height: 55px;
    }

    #trial_item {
        flex-direction: column-reverse;
    }

    #trial_item__description_title {
        font-size: 16px;
        text-align: center;
    }

    #trial_item__preview_image {
        width: 250px;
        padding-left: 30px;
    }

    #trial_item__preview {

        margin-left: 0px;
    }

    .trial_item_button {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .usage_button__text {
        font-size: 20px;
        font-family: "Harmonia", Arial, sans-serif;
        line-height: 1.55;
        font-weight: 500;
    }

    .usage_button__link {
        display: flex;
        justify-content: center;
    }
    /* Footer */
    footer {
        flex-direction: column;
        height: 430px;
        align-items: center;
    }

    #footer_links {
        align-items: baseline;
    }

    #footer_links__menu {
        margin-right: 30px;
        padding: 0;
    }
    .footer_links__menu_list__item {
        width: 138px;
    }
    .footer_links__menu_list__item_link {
        font-size: 14px;
    }

    .footer_links__contacts_list__link {
        font-size: 14px;
    }

    #footer_buttons {
        align-items: center;
    }

    #footer_buttons__copyright {
        align-items: center;
    }

    #footer_button__link {
        margin-bottom: 20px;
        width: 280px;
        height: 55px;
    }

    #footer_button {
        width: 280px;
        height: 55px;
    }

    /* Policy */
    #policy_title {
        font-size: 18px;
        text-align: center;
    }
    .policy_text {
        font-size: 12px;
        width: 310px;
    }

    /* test */

    #test_table {
        flex-direction: row;
    }

    .test_table__row {
        flex-direction: column;
        height: auto;
    }

    .test_table__row_item__text {
        font-size: 14px;
    }

    .test_table__row_item {
        width: 100px;
        height: 80px;
    }

    #map {
        height: 80px;
        width: 8vw;
    }

    #test_table__row_item__photo {
        width: 40px;
    }

    #test_buttons {
        flex-direction: column;
    }

    #test {
        height: auto;
    }

    .test_item__btn {
        width: 270px;
    }

    .test_button__text {
        font-size: 14px;
    }

    .test_item__btn {
        margin-left: 0px;
        margin-top: 30px;
        /* margin-bottom: 30px; */
    }

    #test_button_back {
        width: 270px;
    }

    #test_button_back__link {
        width: 270px;
    }
}

/* Landscape phones and smaller */
@media (max-width: 480px) {
    #header_logo {
        width: 120px;
    }

    .nav {
        user-select: none;
        display: block;

    }
    menu {
        display: none;
    }
    header {
        padding: 0 5% 0 5%;
        justify-content: space-between;
        width: 90%;

    }

    /* Description */
    #description {
        flex-direction: column;
        background-repeat: no-repeat;
        background-position-y: bottom;
        justify-content: space-evenly;
        background-size: 900px;

    }

    #decription_info {
        margin-top: 90px;
    }
    #description_title {
        font-size: 20px;
    }
    #description_text {
        font-size: 12px;
    }
    .description_btn {
        width: 160px;
        height: 35px;
    }
    #description_btn__link_get {
        font-size: 14px;
        margin-bottom: 0px;
    }
    #description_btn__link_buy {
        font-size: 14px;
        margin-bottom: 0px;
    }
    #description_preview {
        grid-template-columns: repeat(7, 40px);
        grid-template-rows: repeat(7, 40px);
    }
    #description_preview__law {
        width: 200px;
    }
    #description_buttons {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    #d_btn_buy {
        align-self: flex-end;
    }
    #description_preview__bg {
        position: absolute;
        left: 0px;
        top: 488px;
        width: 690px;
    }
    #description_preview__pen {
        width: 50px;
        top: -30px;
        left: -50px;
    }
    #description_preview__loop {
        top: -100px;
        left: 80px;
        width: 50px;
    }
    #description_preview__shield {
        top: -100px;
        right: 30px;
        width: 50px;
    }
    #description_preview__pc {
        width: 80px;
        top: -40px;
        left: 30px;

    }

    /* Benefits */

    .benefits_item {
        flex-direction: column;
        justify-content: flex-start;
    }

    .benefits_item__preview_image {
        width: 160px;
    }

    .benefits_item__preview_number {
        width: 100px;
    }

    .benefits_item__preview {
        background-size: contain;
        height: 240px;
    }

    .benefits_item__preview_number-left {
        bottom: 70px;
        left: 0px;
    }

    .benefits_item__preview_image-left {
        left: 50px;
        top: 40px;
    }

    .section_title {
        font-size: 30px;
        text-align: center;
    }

    .benefits_item__description_title {
        font-size: 20px;
    }

    .benefits_item__description_text {
        font-size: 15px;
    }
    .pr20 {
        padding-right: 0px;
    }
    .pl20 {
        padding-left: 0px;
    }

    #benefits_item-invert {
        flex-direction: column-reverse;
    }

    .benefits_item__preview_number-right {
        bottom: 50px;
        right: 0px;
    }

    .benefits_item__preview_image-right {
        top: 80px;
        right: 100px;
    }

    .benefits_item__description {
        padding: 0 20px 0 20px;
    }

    .txt-aling_right {
        text-align: right;
    }

    /* Usage */
    #usage_items {
        flex-direction: column;
    }

    #usage_container {
        height: 2350px;
    }

    .mr30 {
        margin-right: 0px;
        margin-bottom: 30px;
    }

    .usage_button__text {
        color: #000000;
        font-size: 14px;
        font-family: "Harmonia", Arial, sans-serif;
        line-height: 1.55;
        font-weight: 500;
    }

    .usage_button {
        width: 315px;
        height: 55px;
    }

    .usage_button__image {
        width: 45px;
    }

    /* Demo */

    #demo {
        height: 1380px;
    }
    #demo_items {
        flex-direction: column;
    }

    #demo_item__sign {
        width: 185px;
        left: 50px;
        top: 200px;
    }
    #demo_item__sign_rot {
        transform: rotate(90deg);
    }

    #demo_item__arrow_left {
        top: 60px;
        left: 230px;
    }

    #demo_item__image_text {
        left: -200px;
        top: 370px;
    }

    #demo_item__images {
        grid-template-columns: repeat(5, 58px);
        grid-template-rows: repeat(3, 87,8px);
    }

    .demo_item__image {
        width: 290px;
    }

    #integration_container {
        flex-direction: column-reverse;
        height: 950px;
    }

    .cloud_top {
        height: 40px;
    }

    .cloud_bottom {
        height: 40px;
    }

    #integration_preview__android {
        width: 105px;
    }

    #integration_preview__1c {
        width: 70px;
    }

    #integration_preview__wheel {
        width: 40px;
    }

    #integration_preview__bitrix {
        width: 55px;
    }

    #integration_preview__n {
        width: 50px;
    }

    #integration_preview__amocrm {
        width: 75px;
    }

    #integration_preview__api {
        width: 45px;
    }

    #integration_preview__megaplan {
        width: 75px;
    }

    #integration_preview__pen_container {
        height: 110px;
        width: 110px;
    }

    .integration_preview__line {
        width: 30px;
    }

    #integration_preview__pen {
        width: 50px;
    }

    #cross1 {
        width: 20px;
    }

    #integration_preview__pen_item2 {
        width: 90px;
    }

    #integration_preview {
        grid-template-columns: repeat(9, 38px);
        grid-template-rows: repeat(9, 38px);
        margin-top: 40px;
    }

    #integration_description__title {
        font-size: 20px;
        text-align: center;
    }

    #integration_description {
        margin-left: 0px;
    }

    #integration_description__text {
        text-align: center;
        font-size: 14px;
    }

    /* How To */

    #howto_title {
        font-size: 20px;
    }

    .howto_step__text {
        font-size: 15px;
        margin-left: 20px;
        margin-top: 15px;
        margin-bottom: 15px;
        width: 200px;
    }

    #howto_step__play_market {
        width: 130px;
        margin-left: 22px;
    }

    #howto_step_description {
        font-size: 12px;
        width: 200px;
        top: -20px;
        position: relative;
        left: -5px;
    }

    #howto_steps__line {
        height: 720px;
        left: 79px;
        top: 80px;
    }
    .howto_step {
        justify-content: center;
    }

    .step_expanded {
        align-items: center;
    }

    .howto_steps__button {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        font-family: "Harmonia", Arial, sans-serif;
        line-height: 1.55;
        font-weight: 500;
        border-width: 1px;
        border-radius: 5px;
        opacity: 0.4;
    }

    #howto_step__button {
        position: relative;
        left: -35px;
    }

    .text_underline {
        width: 70px;
        height: 5px;
        top: -12px;
        left: -50px;
    }

    #howto_step__1_desc {
        flex-direction: column;
        margin-top: 40px;
        align-items: end;
    }

    .howto_btn {
        width: 250px;
        height: 55px;
    }

    #howto_btn__link_order {
        width: 250px;
        height: 55px;

        font-size: 20px;
        font-family: "Harmonia", Arial, sans-serif;
        line-height: 1.55;
        font-weight: 500;
        border-width: 1px;
        border-radius: 5px;
    }

    #d_btn_order {
        width: 250px;
        height: 55px;
    }

    #trial_item {
        flex-direction: column-reverse;
    }

    #trial_item__description_title {
        font-size: 16px;
        text-align: center;
    }

    #trial_item__preview_image {
        width: 250px;
        padding-left: 30px;
    }

    #trial_item__preview {

        margin-left: 0px;
    }

    .trial_item_button {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .usage_button__text {
        font-size: 20px;
        font-family: "Harmonia", Arial, sans-serif;
        line-height: 1.55;
        font-weight: 500;
    }

    .usage_button__link {
        display: flex;
        justify-content: center;
    }
    /* Footer */
    footer {
        flex-direction: column;
        height: 430px;
        align-items: center;
    }

    #footer_links {
        align-items: baseline;
    }

    #footer_links__menu {
        margin-right: 30px;
        padding: 0;
    }
    .footer_links__menu_list__item {
        width: 138px;
    }
    .footer_links__menu_list__item_link {
        font-size: 14px;
    }

    .footer_links__contacts_list__link {
        font-size: 14px;
    }

    #footer_buttons {
        align-items: center;
    }

    #footer_buttons__copyright {
        align-items: center;
    }

    #footer_button__link {
        margin-bottom: 20px;
        width: 280px;
        height: 55px;
    }

    #footer_button {
        width: 280px;
        height: 55px;
    }

    /* Policy */
    #policy_title {
        font-size: 18px;
        text-align: center;
    }
    .policy_text {
        font-size: 12px;
        width: 310px;
    }

    /* test */

    #test_table {
        flex-direction: row;
    }

    .test_table__row {
        flex-direction: column;
        height: auto;
    }

    .test_table__row_item__text {
        font-size: 10px;
    }

    .test_table__row_item {
        width: 70px;
        height: 80px;
    }

    #map {
        height: 80px;
        width: 8vw;
    }


    #test_table__row_item__photo {
        width: 40px;
    }

    #test_buttons {
        flex-direction: column;
    }

    #test {
        height: auto;
    }

    .test_item__btn {
        width: 270px;
    }

    .test_button__text {
        font-size: 14px;
    }

    .test_item__btn {
        margin-left: 0px;
        margin-top: 30px;
        /* margin-bottom: 30px; */
    }

    #test_button_back {
        width: 270px;
    }

    #test_button_back__link {
        width: 270px;
    }

    .site-login label.form-label.modal_body__text {
        font-size: 14px;
    }

    .site-login .row.jumbotron {
        padding: 40px;
        margin: 0;
        width: 80%;
    }
}

@media (max-width: 321px) {
    #integration_preview {
        grid-template-columns: repeat(9, 32px);
        grid-template-rows: repeat(9, 32px);
    }
    #decription_info {
        position: relative;
        top: 70px;
        margin-top: 0;
    }

    .usage_button {
        width: 285px;

    }

    .usage_button__text {
        font-size: 14px;
    }

    #demo_item__image_text {
        font-size: 15px;
    }

    #trial_item__preview_image {
        padding-left: 0px
    }
}
